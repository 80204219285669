<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Generate Report</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow p-5 overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div class="flex">
              <div class="flex flex-col">
                <label v-if="reportOn != 'transactions'" for="from">From</label>
                <label v-else for="from">Date</label>
                <input
                  class="border rounded w-80 p-2"
                  type="date"
                  name="from"
                  v-model="from"
                  id="from"
                />
              </div>

              <div v-if="reportOn != 'transactions'" class="flex flex-col ml-4">
                <label for="from">To</label>
                <input
                  class="border rounded w-80 p-2"
                  type="date"
                  name="to"
                  v-model="to"
                  id="to"
                />
              </div>
         
              <div class="flex">
                <div class="flex flex-col mr-4">
                  <label for="agent">Select Agent</label>
                  <select
                    class="border rounded w-80 p-2"
                    v-model="selectedAgent"
                    id="agent"
                  >
                    <option value="all">All Agents</option>
                    <option v-for="agent in agents" :key="agent.id" :value="agent.id">
                      {{ agent.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div>
              <p v-if="showError">Show Error</p>
            </div>
            <div class="mt-4">
              <button
                @click="generate()"
                class="bg-green-400 px-6 py-2 rounded-md hover:bg-green-500 disabled:opacity-50"
              >
                <span v-if="!isLoading">Generate</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

// import BranchSales from "../../components/Charts/BranchSales";
// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",
  layout: "default",

  data: () => ({
    branchs: null,
    staffs: null,
    isLoading: false,
    staff: "all",
    branch: "all",
    reportOn: "",
    reportBy: "",
    from: "",
    to: "",
    selectedAgent: "all", // Default value for the select input
    agents: [], 
    showError: false,
  }),


  created() {
    this.loadAgents(); // Call a method to load the list of agents
  },



  computed:  {
    ...mapGetters("auth", ["user"]),
    validation: function () {
      if (this.from == "" || this.to == "") {
        if (this.from != "") {
          return true;
        }

        return false;
      }

      return true;
    },
    
  },


  methods: {

    async loadAgents() {
      try {
        const { data } = await axios.get(`/agent/users`);
        this.agents = data;

        console.log('data in agent list', data);
      } catch (error) {
        console.error("Error loading agents:", error);
      }
    },

    async generate() {
      this.isLoading = true;
      console.log("goo", this.from);
      console.log("goo", this.to);
console.log('this.user.agent_id}', this.user.data.id);
      try {
        const  {data}  = await axios.get(`agent/myreport?from=${this.from}&to=${this.to}&agent_id=${this.user.data.id}&created_by=${this.selectedAgent}`
        );

        console.log("data in generate", data);

        if (data) {
          this.$router.push({
            name: "report_result",
            params: {
              result: data,
              to: this.to,
              from: this.from,
            },
          });
        }

        this.report = data;
        this.isLoading = false;
        return true;
      } catch (e) {
        console.log("Error", e);
        this.isLoading = false;

        return false;
      }
    },
  },
};
</script>
